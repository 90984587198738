.login_form {
    display: grid;
    align-items: center;
    text-align: center;
    grid-gap: 0.5em;
}

.login_label {
    text-align: center;
    margin-top: 10px;
    color: rgb(30, 76, 161);
}

.login_email_container {
    display: grid;
    justify-content: center;
    grid-template-columns: max-content max-content;
    grid-gap: 0.5em;
}

.login_continue_button {
    color: black !important;
}

.login_reset_password_label {
    background: transparent !important;
    cursor: pointer;
    text-align: center;
}

.login_label_container {
    text-align: center;
    display: grid;
}

.login_error_label {
    background: transparent !important;
    color: red !important;
}

.login_container {
    display: grid;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.login_container_mobile {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 50px 30px;
    text-align: center;
}