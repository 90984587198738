.mt-2 {
    margin-top: 10px;
}
.uploaded_block {
    width: 100%;
}
.uploaded_qr {
    width: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    margin-top: 24px;
    height: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.uploaded_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.uploaded_button {
    border-radius: 5px !important;
    margin: 0 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 5px 20px !important;
    text-align: start !important;
    background: #888 !important;
    color: #fff !important;
    width: auto;
}
.uploaded_dropdown {
    width: 46% !important;
    margin-bottom: 20px !important;
}