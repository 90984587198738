

.inputContainer{
    display: flex;
}

.receipt{
    background-color: rgb(225, 225, 225);
    margin-top: 15px;
    width: 21vw;
    margin-right: 20px;
    height: unset;
}

.receipt > p{
   text-align: center;
   font-size: 12px;
   margin-bottom: 10px;
}

.imageWrapper{
    padding: 10px;
    background-color: rgb(183, 142, 62);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.time{
    padding: 5vh;
    display: flex;
    background-color: rgb(227,177,78);
}

.amount{
    text-align: center;
    padding: 4vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
}

.reasons{
    padding: 10px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.total{
    padding: 10px;
    background-color: rgb(255, 255, 255);
    display: flex;
    height: 7vh;
    justify-content: space-between;
    align-items: center;
}


.printDownload{
    display: flex;
    align-items: safe center;
    justify-content: safe center;
    height: 40vh;
}

.action{
   object-fit: contain;
}

.receipt_details{
    width: 85%;
}

.receipt{
    width: 100%;
}


.donorInfo{
    display: flex;
    align-items: flex-start;
    justify-content: right;
    flex-direction: column;
    padding: 10px;
    color: #fff9dc;
    background-color: rgb(227,177,78);
}

.information_for_donation{
    margin: 5px;
    padding: 5px;
    justify-content: space-between;
    border: 1px solid lightgray;
}

.proceed_container{
    display: flex;
}

.proceed_container_mobile{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}