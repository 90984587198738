
*{
  margin: 0;
  scroll-behavior: smooth;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  font-family: "Fira Sans", sans-serif;
}

.app {
  height: 100%;
}

.workspace-container {
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: max-content auto max-content;
  grid-gap: 10px;
}

