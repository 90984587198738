.sides_menu {
    width: 300px;
    height: 450px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
.sides_menu h2 {
    text-decoration: underline;
    font-size: 30px;
}
.sides_menu a {
    color: #888;
    text-decoration: underline;
    padding: 15px;
    width: 100%;
    text-align: center;
    position: relative;
    cursor: pointer;
}
.sides_menu a::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    background: #888;
}
.active {
    font-weight: 600;
    color: #888;
}