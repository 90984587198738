.mt-2 {
    margin-top: 10px;
}
.create_block {
    width: 100%;
}
.create_qr {
    width: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    margin-top: 24px;
    height: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.create_qr_content {
    flex: 0 0 66%;
    height: 100%;
    border-right: 1px solid rgba(0,0,0,0.16);
    padding: 10px;
}
.create_qr_result {
    width: 34%;
    flex: 0 0 34%;
    height: 100%;
    padding: 10px;
}
.create_qr_result_img {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.create_qr_result_img svg {
    width: 128px;
    height: auto;
}
.create_qr_result_button_block {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.create_qr_result_button_create {
    background: #8bc34a;
    flex: 0 0 48%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.create_qr_result_button_download {
    background: #80d3f9;
    flex: 0 0 48%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.create_qr_result_button_download_block {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}
.create_qr_result_button_type {
    background: transparent;
    border: 1px solid #80d3f9;
    color: #80d3f9;
    width: auto;
    padding: 10px;
}
.create_qr_result_text {
    color: #989898;
    font-size: 12px;
    font-weight: 600;
    margin-top: 20px;
}
.create_qr_content_title {
    border: 1px solid rgba(0,0,0,0.16);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.create_qr_content_title_color {
    width: 40px;
    height: 40px;
    background: rgb(48, 48, 255);
}
.create_qr_content_title_text {
    height: 40px;
    border-radius: 0;
    border: none;
    margin: 0;
    font-weight: 500 !important;
}
.create_qr_content_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.create_qr_content_reason {
    flex: 0 0 65%;
    margin-top: 10px;
}
.create_qr_content_ammount {
    flex: 0 0 32%;
    margin-top: 10px;
}
.create_qr_content_textarea {
    width: 100%;
    margin-top: 10px;
}
.create_qr_content_reason_text,
.create_qr_content_ammount_text,
.create_qr_content_textarea_text {
    text-align: start;
    font-size: 14px;
    margin: 0;
}
.create_qr_content_reason_input,
.create_qr_content_ammount_input {
    width: 100%;
    border-radius: 0;
    padding: 5px 10px;
    margin: 0;
}
.create_qr_content_textarea_input {
    width: 100%;
    border: 1px solid #d6d1d5;
}
.create_qr_content_card {
    border: 2px solid #d6d1d5;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
}
.create_qr_content_card_icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #f2f8fc;
}
.create_qr_content_card_icon i {
    height: 20px;
}
.create_qr_content_card_text {
    margin-left: 10px;
    text-transform: uppercase;
    color: #9c9b9c;
}