.user_card_donation_label {
    text-decoration: underline;
}

.user_card_container  h3{
    cursor: pointer;
}

.user_card_container h2 {
    cursor: pointer;
}