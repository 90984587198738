.activation_red_text {
    text-align: center;
    margin-top: 10px;
    color: red;
}
.activation_blue_text {
    text-align: center;
    margin-top: 10px;
    color: rgb(30, 76, 161);
}

.activation_link {
    text-decoration: none;
}