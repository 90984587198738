.donations_block {
    width: 100%;
}
.donations_card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    border: 1px solid #888;
    border-radius: 0 5px 5px 0;
    padding: 10px;
    border-left: 4px solid rgb(30, 76, 161);
    margin-bottom: 10px;
}
.donations_title {
    color: #888;
    margin: 0;
}
.donations_dropdown {
    border-radius: 5px !important;
    margin: 0 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    padding: 5px 20px !important;
    text-align: start !important;
    background: #888 !important;
    color: #fff !important;
}
.uploaded_form {
    height: 300px;
    background: #fff;
    border: none;
    box-shadow: none;
}
.uploaded_modal {
    width: 500px !important;
}
.uploaded_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.uploaded_submit {
    margin-top: 20px !important;
}