

.donation_container{
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 20px;
    padding: 20px;
}

.account_header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.account_header_mobile {
    display: grid;
    grid-gap: 0.5em;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
}

.account_search_container {
    display: grid;
    align-items: center;
    grid-gap: 5px;
    grid-template-columns: repeat(2, max-content);
}

.account_filter_label {
    background: white !important;
}

.account_user_card_container {
    padding: 5px;
}

.account_user_card_container_mobile {
    padding: 5px;
    display: flex;
    justify-content: center;
}

.account_filter_container {
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: 5px;
    display: grid;
    align-items: center;
    grid-gap: 0.5em;
    margin-bottom: 10px;
}

.account_list_header {
    border: 1px solid rgba(34, 36, 38, 0.15);
    display: grid;
    align-items: center;
    grid-template-columns: 0.5% 42% 21% 21% 19%;
    text-align: center;
    grid-template-rows: 30px;
}

.account_list_header_mobile {
    border: 1px solid rgba(34, 36, 38, 0.15);
    display: grid;
    align-items: center;
    grid-template-columns: 4px 475px 236px 236px 213px;
    text-align: center;
    grid-template-rows: 30px;
    width: max-content;
}

.account_list_item {
    display: grid;
    align-items: center;
    grid-template-columns: 0.3% 41% 23% 20% 12%;
    text-align: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    height: 50px;
}

.account_list_item_mobile {
    display: grid;
    align-items: center;
    text-align: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    grid-template-columns: 1% auto 10%;
}

.account_list_item_status {
    background: blue;
    height: 100%;
}

.account_list_label {
    background: white !important;
}

.account_list_text {
    color: grey;
}

.account_list_container {
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.account_list_container_mobile {
    width: 90vw;
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.account_popup_container_mobile {
    display: grid;
    align-items: center;
    grid-gap: 20px;
}

.donation_container_mobile {
    display: grid;
    width: 100%;
    grid-gap: 10px;
}



