.check_email_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.check_email_title {
    color: rgb(30,76,161);
    padding: 30px;
    text-align: center;
}