.customers_block {
    width: 100%;
}
.customers_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.customers_card_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: auto;
    max-height: calc(100% - 60px);
    overflow-y: scroll;
}
.customers_card {
    width: 270px;
    height: 100px;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    margin: 10px;
}
.customers_img {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    background: #888;
}
.customers_text_block {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}
.customers_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #000;
    margin-bottom: 5px;
}
.customers_mail {
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #888;
    text-overflow: ellipsis;
    overflow: hidden; 
    width: 160px; 
    height: 1.2em; 
    white-space: nowrap;
}