.footer_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #555;
    color: #fff;
    padding: 10px 10%;
    width: 100%;
}

.footer_name_container {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    grid-gap: 0.5em;
}

.footer_info {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    grid-gap: 0.5em;
}

.footer_normal_text {
    font-size: 14px;
    color: rgb(211, 211, 211);
}

.footer_small_text {
    font-size: 12px;
    color: rgb(211, 211, 211);
}

.footer_huge_text {
    font-size: 18px;
}

.footer_link_container {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5em;
}

.footer_link_item {
    text-decoration: none;
    color: #fff;
}

.footer_container_mobile {
    display: grid;
    grid-gap: 0.5em;
    justify-content: center;
    text-align: center;
    padding: 10px 0;
    background-color: #555;
    color: #fff;
    width: 100%;
}

.footer_name_container_mobile {
    justify-content: center;
    grid-template-columns: max-content;
    display: grid;
    align-items: center;
    grid-gap: 0.5em;
}

.footer_info_mobile {
    display: grid;
    align-items: center;
    grid-template-columns: max-content;
    grid-gap: 0.5em;
}

.footer_small_text_mobile {
    font-size: 12px;
    color: rgb(211, 211, 211);
    text-align: center;
    align-items: center;
    justify-content: center;
    display: grid;
}