.admin_body {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    max-width: 1400px;
    margin: auto;
}
.admin_content {
    width: calc(100% - 350px);
    height: 450px;
    display: flex;
}