
.reports_block {
    width: 100%;
}
.reports_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.reports_card_block {
    overflow-y: scroll;
    padding: 10px;
    height: calc(100% - 60px);
}
.reports_card {
    width: 100%;
    padding: 15px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    margin-bottom: 20px;
}
.reports_card_header {
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 2px solid #888;
    margin: 0;
}
.reports_row {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reports_row:nth-child(2n) {
    background: rgba(136, 136, 136, 0.2);
}
.reports_button_block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.reports_button {
    background: transparent;
    border: none;
    color: rgb(30, 76, 161);
    width: fit-content;
    padding: 0;
    text-transform: uppercase;
    margin: 10px 0 0;
}
.reports_button:hover {
    background: transparent;
    border: none;
    color: rgb(30, 76, 161);
}