

body {
  margin: 0;
  scrollbar-width: thin;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus{
  font-weight: bold;
}


hr {
  background-color: #e4e4e4 !important;
  color: #e4e4e4 !important;
  border: solid 0.5px #e4e4e4 !important;
  height: 0.5px !important;
}
/* 
main{
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
} */
