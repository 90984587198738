.header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(30, 76, 161);
    padding: 10px 10%;
    color: #fff;
    width: 100%;
}

.header_user_info {
    text-decoration: underline;
    cursor: pointer;
}

.header_content_container {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 0.5em;
}

.header_container_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(30, 76, 161);
    padding: 10px 5%;
    color: #fff;
    width: 100%;
}

.header_welcome {
    display: none;
}